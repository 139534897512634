.faq {
    top: 20% !important;
    background-color: white;
    margin-top: 5%;
    padding: 50px 0px;
}

.faq nav {
    margin-top: 5%;
}

.faq nav a {
    color: #33495E;
    text-decoration: none;
    font-weight: 600;
}

.faq nav a:hover {
    color: #000;
}

#contact-li {
    color: black;
    background-color: whitesmoke;
    border-radius: 10px;
    padding: 4px 10px;
}

.contact p,
.contact li {
    font-size: 20px;
    padding-top: 20px;
}

.contact h1 {
    padding-bottom: 20px;
    font-size: 40px;
}

.contact-form {
    background-color: whitesmoke;
}

.contact-form p {
    font-size: 30px;
    text-align: center;
    padding: 0px 50px;
}

.contact-form label {
    font-weight: 600;
    color: #000;
    padding-bottom: 10px;
}

.contact-form input,
.contact-form textarea {
    width: 100%;
    padding: 10px 5px;
    outline: none;
    border: none;
}

#contact-btn {
    background-color: white;
    font-size: 18px;
    padding: 10px 30px;
    float: right;
    outline: none;
    border: none;
    border-radius: 10px;
}

#contact-btn:hover {
    background-color: black;
    color: white;
}

.contactImg img {
    width: 40px;
}

.contactImg span {
    font-size: 16px;
}

.contactImg p,
.contactImg a {
    font-size: 20px;
    padding-top: 10px;
    color: inherit;
    text-decoration: none;
    font-weight: 600;
}

@media (max-width: 425px) {
    .faq nav {
        margin-top: 10%;
    }

    .contact-form p {
        font-size: 14px;
        padding: 0px 10px;
    }

    .contact h1 {
        padding-bottom: 0px;
        font-size: 26px;
    }
}