.signup {
  /* height: 100vh; */
  overflow: hidden;
  background-color: #fff;
}
.signup .one {
  background-image: url(../images/career.jpg);
  background-size: cover;
  background-position: center;
  object-fit: contain;
  position: relative;
  height: 100vh;
  border-radius: 5px;
  background-color: #b4a79e;
  margin: 5px 0px;
}
.signup .inp {
  padding: 40px 20px;
}
.signup .inp select {
  width: 100%;
  height: 50px;
  color: #20247b;
  border: 2px solid #20247b;
  padding: 0px 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}
.signup .inp input {
  border-radius: 5px;
  width: 100%;
  height: 50px;
  padding: 0px 20px;
  color: #20247b;
  border: 2px solid #20247b;
  background-color: transparent;
  margin-bottom: 20px;
}
.signup .inp label {
  font-size: 15px;
  margin-bottom: 7px;
  font-weight: 600;
  color: #20247b;
}
.signup .inp .btn3 {
  background-color: #20247b;
  border: 2px solid #fff;
  color: #fff;
  font-weight: 600;
  padding: 10px 25px;
  width: 100%;
  font-size: 20px;
  border-radius: 10px;
  margin: 0;
}
.signup .inp .btn3:hover {
  background-color: #23265e;
  color: #fff;
  border: 2px solid #20247b;
}
.signup .navs {
  font-family: "Bebas Neue", sans-serif;
  text-align: center;
  color: #20247b;
  font-size: 25px;
  text-decoration: none;
  font-weight: 800;
}
.signup .navs span {
  font-family: "Bebas Neue", sans-serif;
}

#msg {
  font-family: "Bebas Neue", sans-serif;
  text-align: center;
  color: black;
  font-size: 18px;
  text-decoration: none;
  text-transform: uppercase;
  margin-bottom: -20px;
  font-weight: 500;
}


