.about-card {
    padding: 20px;
    margin-bottom: 20px;
}

.about-card img {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    object-fit: cover;
}

.about-card h3 {
    font-size: 20px;
    margin-top: 30px;
    text-align: center;
}

.about-card p {
    font-size: 16px;
    margin-top: 20px;
    text-align: center;
}