* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}

.transparent {
  opacity: 0.7; /* Modify as needed */
}

.fadeIn {
  animation: fadeIn 0.5s forwards;
}

.scrolled {
  background-color: white /* your desired background color for scrolled state */;
  color: black;
}

.scrolled a {
  color: black !important;
}

.article-img {
  background-image: url("../images/car.jpeg");
  height: 300px;
  width: 100% !important;
  margin-top: 50px;
  background-repeat: no-repeat;
  background-size: contain;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

/* nav section */
#mynav img {
  width: 140px;
  height: 80px;
  border-radius: 50%;
}

#navbarSS {
  justify-content: right;
  font-size: 1.7rem;
}

#navbarSS a {
  text-transform: capitalize;
  color: #fff;
  text-decoration: none;
  margin: 0px 30px;
  font-weight: 650;
  font-size: 1.25rem;
}

#navbarSS a:hover {
  color: #121121;
}

#mynav {
  transition: background-color 0.3s ease, opacity 0.3s ease;
}

#mynav.transparent {
  background-color: #ffffff;
}

#mynav.transparent a {
  color: #121121;
}

.navbar-toggler {
  box-shadow: none !important;
}

/* banner section */

#banner .carousel-image {
  height: 100vh;
  object-fit: cover;
  transition: transform 0.5s ease;
  background-color: #e4b97d;
}

.carousel-item.active .carousel-image {
  transform: scale(1.2);
}

.carousel-item h2 {
  font-size: 80px;
  color: #281f1c;
  font-weight: 900;
}

.carousel-indicators {
  display: flex !important;
  justify-content: space-evenly !important;
}

.banner-indicator {
  position: absolute;
  top: 85%;
  /* display: flex;
    justify-content: space-around; */
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  gap: 10%;
  padding: 0px 100px;
}

.banner-indicator div {
  cursor: pointer;
  color: #000 !important;
}

.banner-indicator div:hover {
  color: white !important;
  text-decoration: 1px underline white !important;
}

.carousel-item p {
  color: #fff;
  font-weight: 700;
}

.carousel-item img {
  opacity: 0.7;
}

#cardSlideImg {
  opacity: 1;
}

.modal-a a {
  color: inherit;
  text-decoration: none;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

#achieve,
#achieve1,
#achieve2 {
  display: flex;
  /* height: 160px; */
}

#target,
#target1,
#target2 {
  display: none;
  animation: fadeIn 0.5s forwards;
  height: 160px;
}

.animateTarget h3 {
  font-size: 28px;
  color: #34495e;
  font-weight: 600;
}

.animateTarget p {
  color: #000;
}

.animateTarget div div {
  background-color: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

footer svg {
  cursor: pointer;
  margin: 0px 20px;
}

footer svg:hover {
  color: #34495e;
}

footer p {
  font-size: 14px;
  font-weight: 600;
  color: #34495e;
}

.offer p{
  font-size: 20px;
  color: #828687;
}

.offer h4{
  color: #000;
}

.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 425px) {
  .article-img {
    height: 100px !important;
    margin-bottom: -10px !important;
    background-size: contain;
  }

  #banner .carousel-image {
    height: 50vh;
    object-fit: cover;
    transition: transform 0.5s ease;
  }

  .banner-indicator div {
    font-size: 10px;
    margin-top: -2px;
  }

  .carousel-caption {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .carousel-caption h2 {
    font-size: 20px !important;
    font-weight: 900;
    color: #fff;
  }

  .carousel-caption {
    display: inline-block;
    height: fit-content;
  }

  /* .carousel-inner */

  .carousel-item p {
    font-size: 14px !important;
    color: white;
  }

  #mynav img {
    width: 80px;
    height: 50px;
  }
}

@media (max-width: 768px) {
  .article-img {
    height: 150px;
    margin-bottom: 20px;
    width: 100% !important;
  }
  .carousel-item h2 {
    font-size: 40px;
  }

  .banner-indicator {
    position: absolute;
    top: 85%;
    font-size: 12px;
    display: flex;
    justify-content: space-evenly;
    padding: 0;
  }

  .banner-indicator div {
    margin-bottom: 4px;
  }

  #navbarSS {
    background-color: #fff;
  }

  #mynav.transparent a {
    color: #121121;
  }

  #mynav a {
    color: #828687;
    font-size: 14px;
    padding: 3px;
    margin: 0;
  }

  #mynav a :active {
    color: red;
  }
}
