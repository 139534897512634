.quiz {
  background-color: #fff;
  height: 100%;
  position: relative;
}
/* .quiz .nav {
  background-color: #20247b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 30px;
} */

.quiz .nav {
    position: fixed;
    top: 0; /* Position it at the top of the viewport */
    left: 0;
    right: 0; /* Make it span the full width */
    background-color: #20247b;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px 30px;
    margin: 0 10px;
    z-index: 1000; /* Adjust the z-index as needed to ensure it's above other content */
  }
  
.quiz .nav h2,
.quiz .nav h3 {
  font-family: "Courier New", Courier, monospace;
  font-weight: 600;
}
.quiz .nav p {
  margin: 0px;
}
.quiz .nav .time {
  display: flex;
}
.quiz .nav .time h2 {
  margin: 0px;
}
.quiz .counter {
  align-items: center;
  text-align: center;
  background-color: #20247b;
  color: #fff;
  font-weight: 600;
  margin-top: 30px;
}
.quiz .center {
  text-align: left;
  color: #20247b;
}
.quiz .center h3 {
  font-weight: 600;
  /* background-color: red; */
  width: 100%;
}
.quiz .center .section {
  display: flex;
  align-items: center;
  padding: 10px 20px;
  cursor: pointer;
}
.quiz .center .section:hover {
  border: 3px solid #20247b;
  border-radius: 5px;
}
.quiz .center .section p {
  margin-left: 20px;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 0;
}
.quiz .center .section input {
  width: 20px;
  height: 20px;
  align-items: center;
}
.quiz .down {
  background-color: #20247b;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  padding: 0px 50px;
  width: 100%;
}
.quiz .down button {
  background-color: transparent;
  border: none;
  border: 3px solid #fff;
  color: #fff;
  padding: 10px 40px;
  font-size: 20px;
  font-weight: 600;
}
.quiz .down button:hover {
  color: #20247b;
  background-color: #fff;
}
.quiz .down p {
  line-height: 20px;
  margin-right: 5px;
}
.quiz .down #namy,
.quiz .down #nam {
  font-size: 30px;
  color: orange;
}
.quiz .down .share {
  display: flex;
  align-items: center;
}
.quiz .down img {
  width: 30%;
}
