.faq {
  background-color: white;
  margin-top: 5%;
  padding: 50px 0px;
}

.accordion-item {
  background-color: white;
}

.accordion-header button {
  box-shadow: none !important;
  background-color: whitesmoke;
  min-height: 70px;
  height: fit-content;
  font-size: 20px;
  color: #34495e;
  font-weight: 600;
}

.accordion-header button:hover {
  color: #1d5d9d;
}

.faq h1 {
  color: #34495e;
  font-size: 34px;
}

.accordion-body {
  font-size: 18px;
}

.form-warning p {
  font-size: 12px;
  margin-top: 15px;
  text-align: left;
}

.survey {
  position: relative; /* Ensure the element is positioned to use the ::before */
  z-index: 1; /* Set a stacking context for other content */
  /* Your other styles */
  background-color: #fff; /* Background color, if needed */
}

.survey h2{
    font-weight: 700;
}

.survey::before {
  content: ""; /* Necessary to apply styles */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../images/logo.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; /* Adjust to fit your needs (e.g., contain, cover) */
  opacity: 0.4; /* Set the opacity level here */
  z-index: -1; /* Ensure the background appears behind content */
}

@media (max-width: 425px) {
  .faq {
    margin-top: 10%;
  }

  .faq h1 {
    font-size: 26px;
  }

  .accordion-header button {
    font-size: 16px;
  }

  .faq h1 {
    font-size: 20px;
    margin-top: 20px;
  }

  .accordion-body {
    font-size: 14px;
  }
}
