/* purpose-section */
.purpose-section {
    width: 100%;
}

.card h2 {
    font-size: 1.5rem;
    line-height: 2rem;
    color: #3b2003;
    margin-bottom: 1.5rem;
}

.card p {
    color: #34495E;
    font-size: 18px;
    line-height: 40px;
}

.card {
    border: none !important;
    background-color: inherit;
}

.mission {
    box-shadow: 0px 0px 2px 3px silver;
}

.mission:hover {
    transform: scale(1.01);
}

.inner-img img {
    height: 100%;
    width: 100%;
    transition: all 1s ease;
    object-fit: cover;
}

.inner-img {
    height: 500px;
    max-height: 500px;
}

.card-row-img {
    height: 500px;
    max-height: 500px;
    overflow: hidden;
}

.card-row-img img:hover,
.culture-card:hover img {
    transform: scale(1.2);
}

.share-price {
    border-bottom: 1px solid #000;
    margin-bottom: 50px;
}

.share-price h3 {
    font-size: 22px;
    font-weight: 500;
    background-color: silver;
    border-radius: 50%;
    width: fit-content;
    height: fit-content;
    padding: 10px;
    letter-spacing: -1px;
}

.share-price h4 {
    font-size: 18px;
    font-weight: 500;
}

.share-price p {
    font-size: 14px;
}

.share-price span {
    font-size: 40px;
    color: green;
}

.corporate-card {
    background-color: white;
    min-height: 500px;
    border-radius: 8px;
    box-shadow: 0px 0px 5px 5px whitesmoke;
}

.innerCard {
    min-height: 400px;
    box-shadow: 0px 0px 2px 2px gainsboro;
}

.corporate-card p {
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    color: #34495E;
    text-align: justify;
}

.culture-card p {
    font-weight: 600;
    font-size: 12px;
    cursor: pointer;
    transition: all .6s ease;
    width: fit-content;
}

.corporate-card h3 {
    font-size: 18px;
    height: 50px;
    color: #000;
}

.culture-card h3 {
    font-size: 35px;
}

.culture-card p:hover {
    color: #34495E;
    transform: scale(1.05) translateX(20px);
}

.corporate-card p:hover {
    color: #000;
}

.culture-card {
    background-color: #ebf8f7;
    overflow: hidden;
}

.culture-card .inner-img {
    height: 400px;
    overflow: hidden;
}

.culture-card .inner-img img {
    transition: all 1s ease;
    object-fit: cover;
    object-position: top;
}

@media (max-width:768px) {
    .corporate-card {
        background-color: white;
        min-height: fit-content;
    }
}

@media (max-width:425px) {
    /* .card-row-img {
        height: 150px;
        max-height: 150px;
    } */

    .inner-img img {
        object-fit: cover;
    }

    /* .corporate-card {
        height: 150px;
    } */

    .corporate-card {
        min-height: fit-content;
    }

    .culture-card .inner-img img {
        object-fit: cover;
        object-position: center;
    }
}