.culture-card span {
    font-weight: 600;
    font-size: 14px;
    cursor: pointer;
    transition: all .6s ease;
    width: fit-content;
    cursor: unset;
}

.culture-card h4 {
    font-size: 24px;
}

.culture-card {
    background-color: whitesmoke;
    height: 500px;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.gallery-card {
    min-height: 450px;
    /* height: fit-content; */
}

.gallery-card p{
    font-size: 20px !important;
}

@media (max-width:768px) {
    .gallery-card {
        min-height: fit-content;
        height: fit-content;
    }
}